import { Component } from '@angular/core';
import { PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { isIbanValid, isValidDateFormat } from '@nbg-digital/shared/util';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import * as dayjs from 'dayjs';
import { PaymentService } from '@nbg-digital/customer-service/domain';

export interface PaymentData {
  bic: string;
  iban: string;
  accountNumber: string;
  bankCode: string;
  bankName: string;
  mandate: boolean;
  firstname: string;
  lastname: string;
  changeDate: string;
}

type PaymentInputMode = 'iban' | 'accountNumber';

@Component({
  selector: 'nv-customer-service-payment-data',
  templateUrl: './payment-data.component.html',
})
export class PaymentDataComponent extends PartialFormComponent<PaymentData> {
  inputMode: PaymentInputMode = 'iban';
  minDate = dayjs().startOf('day').format('YYYY-MM-DD');

  private ibanValidators = [Validators.required, isIbanValid];
  private accountNumberValidators = [Validators.required, Validators.pattern('[0-9]{10}')];
  private bankCodeValidators = [Validators.required, Validators.pattern('[0-9]{8}')];

  protected unsubscribe$ = new Subject<void>(); //entwder dieses ng on destroy machen oder dieses hier entfernen mit verwendungen

  constructor(private fBuilder: UntypedFormBuilder, private paymentService: PaymentService) {
    super();

    this.formGroup = this.fBuilder.group({
      bic: '',
      firstname: '',
      iban: '',
      accountNumber: '',
      bankCode: '',
      bankName: '',
      lastname: '',
      mandate: '',
      changeDate: this.minDate,
    });

    this.formGroup
      .get('iban')
      .valueChanges.pipe(
        map((iban) => {
          this.formGroup.patchValue({ iban: iban.toUpperCase() }, { emitEvent: false });
          return iban.toUpperCase();
        }),
        mergeMap((iban) => {
          if (this.formGroup.controls.iban.valid) {
            return this.paymentService.getInfo(iban);
          }
          return of({ bic: '', bankName: '' });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((bankInfo) => {
        this.formGroup.patchValue({ bic: bankInfo.bic, bankName: bankInfo.bankName });
      });

    this.setValidation();

    combineLatest([this.formGroup.get('accountNumber').valueChanges, this.formGroup.get('bankCode').valueChanges])
      .pipe(
        mergeMap(([accountNumber, bankCode]) => {
          if (this.formGroup.get('accountNumber').valid && this.formGroup.get('bankCode').valid) {
            return this.paymentService.getAccountInfo(accountNumber, bankCode);
          }
          return of({ iban: '', bic: '', bankName: '' });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ iban, bic, bankName }) => {
        this.formGroup.patchValue({ iban, bic, bankName }, { emitEvent: false });
      });
  }

  setValidation() {
    const controls = this.formGroup.controls;
    controls.bic.disable();
    controls.bankName.disable();
    controls.firstname.disable();
    controls.lastname.disable();

    controls.changeDate.setValidators([Validators.required, isValidDateFormat()]);
    controls.mandate.setValidators([Validators.requiredTrue]);
    controls.iban.setValidators(this.ibanValidators);
  }

  public toggleInputMode(event: MouseEvent, inputMode: PaymentInputMode) {
    event.preventDefault();
    this.inputMode = inputMode;
    const ibanControl = this.formGroup.get('iban');
    const accountNumberControl = this.formGroup.get('accountNumber');
    const bankCodeControl = this.formGroup.get('bankCode');

    if (inputMode === 'iban') {
      ibanControl.setValidators(this.ibanValidators);
      accountNumberControl.setValue('');
      bankCodeControl.setValue('');
      accountNumberControl.clearValidators();
      bankCodeControl.clearValidators();
      accountNumberControl.setErrors(null);
      bankCodeControl.setErrors(null);
    } else {
      bankCodeControl.setValidators(this.bankCodeValidators);
      ibanControl.setValue('');
      ibanControl.clearValidators();
      accountNumberControl.setValidators(this.accountNumberValidators);
      this.formGroup.updateValueAndValidity();
    }
  }

  protected fromFormData(): PaymentData {
    return {
      ...this.formGroup.getRawValue(),
    };
  }

  protected toFormData(value: Partial<PaymentData>) {
    if (value == null) {
      return '';
    }
    return {
      ...value,
    };
  }
}
