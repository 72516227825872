import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import {
  AddressService,
  ChangeAddressData,
  Customer,
  CustomerServiceStore,
} from '@nbg-digital/customer-service/domain';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CountryService,
  hasCorrectLength,
  isNoFutureDate,
  isValidDateFormat,
  validateOptions,
} from '@nbg-digital/shared/util';
import { AutoCompleteOption, NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';

@Component({
  selector: 'nv-customer-change-details',
  templateUrl: './change-customer-details.component.html',
})
export class ChangeCustomerDetailsComponent implements OnInit, OnDestroy {
  public customer: Customer;
  public customerDetailsForm: UntypedFormGroup;
  public personalDataUrl = '../';
  @ViewChild('modal', { static: true }) private modal: NvModalComponent;
  private unsubscribe$ = new Subject<void>();

  minDate = dayjs().startOf('day').format('YYYY-MM-DD');

  countries$: Observable<AutoCompleteOption[]> = this.countryService
    .getCountryList()
    .pipe(map((countries) => countries.map((c) => ({ value: c.iso, text: c.country }))));

  constructor(
    private customerServiceStore: CustomerServiceStore,
    private fbuilder: UntypedFormBuilder,
    private addressService: AddressService,
    private countryService: CountryService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customerDetailsForm = this.fbuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      birthDate: [null, [Validators.required, isNoFutureDate(), isValidDateFormat()]],
      phone: [
        '',
        [hasCorrectLength(), Validators.pattern('(01[5-7]\\d+$)|(^\\+[1-9]\\d{1,14}$)'), Validators.required],
      ],
      street: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}')]],
      city: ['', [Validators.required, Validators.pattern('[a-zA-ZüöäÜÖÄß/()\\- ]+')]],
      country: ['', [Validators.required, Validators.minLength(2)]],
      changeDate: [this.minDate, [Validators.required, isValidDateFormat()]],
    });
  }

  ngOnInit() {
    this.customerDetailsForm.controls.country.setAsyncValidators(validateOptions(this.countries$));
    this.customerServiceStore
      .selectCustomer()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer) => {
        this.customer = customer;
        this.customerDetailsForm.patchValue({
          ...customer,
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (!this.customerDetailsForm.valid) {
      this.customerDetailsForm.markAllAsTouched();
    } else {
      if (this.customerDetailsForm.dirty) {
        const changeAddressDataObject: ChangeAddressData = {
          ...this.customerDetailsForm.value,
        };
        this.addressService.changeAddressData(changeAddressDataObject).subscribe(() => {
          this.modal.open();
          this.router.navigate([this.personalDataUrl], { relativeTo: this.route });
        });
      }
    }
  }
}
