import { Component } from '@angular/core';
import { ConsentAdminService } from '@nbg-digital/consent-management/domain';

@Component({
  selector: 'nv-consent-management-consent-download',
  templateUrl: './consent-download.component.html',
})
export class ConsentDownloadComponent {
  constructor(private consentAdminService: ConsentAdminService) {}

  downloadEwe(event: Event) {
    event.preventDefault();
    this.consentAdminService.getCsv().subscribe();
  }

  downloadLead(event: Event) {
    event.preventDefault();
    this.consentAdminService.getLead().subscribe();
  }
}
