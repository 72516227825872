import { ContractOverview, ContractService, CustomerServiceStore } from '@nbg-digital/customer-service/domain';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ContractOverviewContractsResolver implements Resolve<ContractOverview[]> {
  constructor(private contractService: ContractService, private customerServiceStore: CustomerServiceStore) {}

  resolve(): Observable<ContractOverview[]> {
    return this.customerServiceStore.selectContracts().pipe(
      first(),
      switchMap((res) => {
        if (res === null) {
          return this.contractService.getContracts().pipe(
            map(({ contracts }) => contracts),
            tap((contracts) => {
              this.customerServiceStore.update({ contracts });
            })
          );
        }
        return of(res);
      })
    );
  }
}
