import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApplicationStore } from '@nbg-digital/application/domain';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { WizardPageComponent } from '@nbg-digital/ui-dpl-components';
import { PaymentData, PaymentDataComponent } from '../../components/payment-data/payment-data.component';

@Component({
  selector: 'nv-application-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent extends WizardPageComponent {
  @ViewChild(PaymentDataComponent) paymentDataComponent: PaymentDataComponent;

  initialPaymentData: Partial<PaymentData>;

  paymentForm: UntypedFormGroup;

  constructor(
    private applicationStore: ApplicationStore,
    private fbuilder: UntypedFormBuilder,
    route: ActivatedRoute,
    element: ElementRef
  ) {
    super(route, element);

    this.paymentForm = this.fbuilder.group({
      paymentData: '',
    });
  }

  protected isFormValid() {
    this.paymentForm.markAllAsTouched();
    return this.paymentForm.valid;
  }

  protected fillInitialDataFromStore() {
    this.applicationStore.state$.pipe(first()).subscribe((state) => {
      if (state.insuranceObjects?.INSUREE) {
        this.initialPaymentData = {
          ...this.initialPaymentData,
          firstname: state.insuranceObjects?.INSUREE?.firstname,
          lastname: state.insuranceObjects?.INSUREE?.lastname,
        };
      }
      if (state.paymentInfo) {
        this.initialPaymentData = {
          ...this.initialPaymentData,
          iban: state.paymentInfo.iban,
          bic: state.paymentInfo.bic,
          mandate: state.paymentInfo.iban != '',
        };
      }
    });
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.paymentForm.setControl(name, form);
  }

  protected beforeNext() {
    const { iban, bic, bankName } = { ...this.paymentDataComponent.value };
    this.applicationStore.setPaymentInfo({ iban, bic, bankName });
  }
}
