import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { InterceptorSkipHeader } from '@nbg-digital/shared/util';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    @Optional() private userFacade: UserManagementFacade,
    @Inject('errorPath') private errorPath: string
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(catchError((error) => this.handleHttpError(error)));
  }

  /**
   * Determines whether to handle the error using the specified status code.
   * @param code The http status code.
   */
  private matchStatusCode(code: number): boolean {
    const ignoredCodes = [401, 403, 409];
    return (code === 0 || code >= 400) && !ignoredCodes.includes(code);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleHttpError = (error: HttpErrorResponse): Observable<any> => {
    if (this.matchStatusCode(error.status)) {
      this.userFacade.resetRegistrationState();
      this.router.navigate([`/${this.errorPath}`]);
    }
    return throwError(error);
  };
}
