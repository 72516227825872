import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendMessageComponent } from './send-message.component';
import {
  NvButtonModule,
  NvErrorModule,
  NvFormInfoContainerModule,
  NvFormModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NvModalModule,
    TranslateModule,
    NvFormModule,
    NvButtonModule,
    ReactiveFormsModule,
    NvErrorModule,
    NvFormInfoContainerModule,
  ],
  declarations: [SendMessageComponent],
  exports: [SendMessageComponent],
})
export class SendMessageModule {}
