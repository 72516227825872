import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NvErrorModule, NvFormModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BirthdateComponent } from './birthdate.component';

@NgModule({
  declarations: [BirthdateComponent],
  imports: [CommonModule, NvErrorModule, ReactiveFormsModule, TranslateModule, NvFormModule, NgxMaskDirective],
  providers: [provideNgxMask()],
  exports: [BirthdateComponent],
})
export class BirthdateModule {}
