import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContractOverviewModule, MessagePreviewModule } from '@nbg-digital/customer-service/feature';

@NgModule({
  declarations: [OverviewComponent],
  imports: [CommonModule, TranslateModule, ContractOverviewModule, MessagePreviewModule],
  exports: [OverviewComponent],
})
export class OverviewModule {}
