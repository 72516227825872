import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContractOverview, CustomerServiceStore, MainBroker } from '@nbg-digital/customer-service/domain';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nv-customer-contract-overview',
  templateUrl: './contract-overview.component.html',
  styleUrls: ['./contract-overview.component.scss'],
})
export class ContractOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public contracts$: Observable<ContractOverview[]>;

  MainBroker = MainBroker;
  formGroup: UntypedFormGroup;

  private defaultMainBrokerClass = 'default-broker';
  private otherMainBrokerClass = 'other-broker';

  constructor(private fBuilder: UntypedFormBuilder, private customerServiceStore: CustomerServiceStore) {
    this.formGroup = this.fBuilder.group({
      subscribeEmail: false,
      subscribePhone: false,
    });
  }

  ngOnInit() {
    this.contracts$ = this.customerServiceStore.selectContracts().pipe(takeUntil(this.unsubscribe$));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getNbgLogoStyle(contract: ContractOverview): string {
    if (contract.mainBroker === MainBroker.Bayerische) {
      return this.otherMainBrokerClass;
    } else {
      return this.defaultMainBrokerClass;
    }
  }
}
