import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentManagementRoutingModule } from './consent-management-routing.module';
import { ConsentConfirmationComponent } from './consent-confirmation/consent-confirmation.component';
import { ConsentWithdrawalComponent } from './consent-withdrawal/consent-withdrawal.component';
import { ConsentConfirmationResolver } from './consent-confirmation/consent-confirmation.resolver';
import { ConsentWithdrawalResolver } from './consent-withdrawal/consent-withdrawal.resolver';
import { CustomerConsentConfirmationResolver } from './customer-consent-confirmation/customer-consent-confirmation.resolver';
import { CustomerConsentWithdrawalResolver } from './customer-consent-withdrawal/customer-consent-withdrawal.resolver';
import { NvContainerModule } from '@nbg-digital/ui-dpl-components';

@NgModule({
  declarations: [ConsentConfirmationComponent, ConsentWithdrawalComponent],
  imports: [ConsentManagementRoutingModule, CommonModule, TranslateModule, NvContainerModule],
  providers: [
    ConsentConfirmationResolver,
    ConsentWithdrawalResolver,
    CustomerConsentConfirmationResolver,
    CustomerConsentWithdrawalResolver,
  ],
})
export class ConsentManagementFeatureModule {}
