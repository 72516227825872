import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { PricingParameters } from '@nbg-digital/application/domain';

@Injectable()
export class PricingResolver implements Resolve<PricingParameters> {
  constructor(private pricingFacade: PricingFacade) {}

  resolve(): Observable<PricingParameters> {
    return this.pricingFacade.pricing$.pipe(first());
  }
}
