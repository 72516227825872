import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentConfirmationResolver } from './consent-confirmation/consent-confirmation.resolver';
import { ConsentWithdrawalResolver } from './consent-withdrawal/consent-withdrawal.resolver';
import { ConsentWithdrawalComponent } from './consent-withdrawal/consent-withdrawal.component';
import { ConsentConfirmationComponent } from './consent-confirmation/consent-confirmation.component';
import { CustomerConsentWithdrawalResolver } from './customer-consent-withdrawal/customer-consent-withdrawal.resolver';
import { CustomerConsentConfirmationResolver } from './customer-consent-confirmation/customer-consent-confirmation.resolver';

const routes: Routes = [
  {
    path: 'bestaetigung/:uuid',
    component: ConsentConfirmationComponent,
    resolve: { status: ConsentConfirmationResolver },
  },
  {
    path: 'widerruf/:uuid',
    component: ConsentWithdrawalComponent,
    resolve: { status: ConsentWithdrawalResolver },
  },
  {
    path: 'kunden/bestaetigung/:uuid',
    component: ConsentConfirmationComponent,
    resolve: { status: CustomerConsentConfirmationResolver },
  },
  {
    path: 'kunden/widerruf/:uuid',
    component: ConsentWithdrawalComponent,
    resolve: { status: CustomerConsentWithdrawalResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ConsentConfirmationResolver, ConsentWithdrawalResolver],
})
export class ConsentManagementRoutingModule {}
