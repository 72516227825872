import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDataComponent } from './payment-data.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  NvCheckboxModule,
  NvErrorModule,
  NvInfoTextContainerModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';

@NgModule({
  declarations: [PaymentDataComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NvErrorModule,
    NvLinkModule,
    NvCheckboxModule,
    NvInfoTextContainerModule,
    NvModalModule,
    NvInputModule,
  ],
  exports: [PaymentDataComponent],
})
export class PaymentDataModule {}
