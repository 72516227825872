import { Component, Input } from '@angular/core';
import { PolicyInformationDkr } from '@nbg-digital/customer-service/domain';

@Component({
  selector: 'nv-customer-policy-specifics-dkr',
  templateUrl: './policy-specifics-dkr.component.html',
})
export class PolicySpecificsDkrComponent {
  @Input() policyInformation: PolicyInformationDkr;
}
