import { PersonalDetailsConfig } from '../../components/personal-details/personal-details-config';
import { AddressDataConfig } from '../../components/address-data/address-data-config';

export const DEFAULT_PERSONAL_DETAILS_CONFIG: PersonalDetailsConfig = {
  titleHidden: false,
  salutationEditable: false,
  birthdateEditable: false,
  cityOfBirthEditable: true,
  cityOfBirthRequired: true,
  nameEditable: true,
  nameRequired: true,
  phoneRequired: true,
  phoneHidden: false,
  nationalityEditable: true,
  nationalityRequired: true,
  nationalityHidden: false,
  validateInsurableAge: true,
};

export const COMPLETE_CUSTOMER_DATA_CONFIG: PersonalDetailsConfig = {
  titleHidden: false,
  salutationEditable: false,
  birthdateEditable: false,
  cityOfBirthEditable: false,
  cityOfBirthRequired: true,
  nameEditable: false,
  nameRequired: true,
  phoneRequired: true,
  phoneHidden: false,
  nationalityEditable: false,
  nationalityRequired: true,
  nationalityHidden: false,
  validateInsurableAge: true,
};

export const CUSTOMER_DATA_WITHOUT_CITY_OF_BIRTH_CONFIG: PersonalDetailsConfig = {
  titleHidden: false,
  salutationEditable: false,
  birthdateEditable: false,
  cityOfBirthEditable: true,
  cityOfBirthRequired: true,
  nameEditable: false,
  nameRequired: true,
  phoneRequired: true,
  phoneHidden: false,
  nationalityEditable: false,
  nationalityRequired: true,
  nationalityHidden: false,
  validateInsurableAge: true,
};

export const BENEFICIARY_PERSONAL_DETAILS_CONFIG: PersonalDetailsConfig = {
  titleHidden: true,
  salutationEditable: true,
  birthdateEditable: true,
  cityOfBirthEditable: true,
  cityOfBirthRequired: true,
  nameEditable: true,
  nameRequired: true,
  phoneRequired: false,
  phoneHidden: true,
  nationalityEditable: false,
  nationalityRequired: false,
  nationalityHidden: true,
  validateInsurableAge: false,
};

export const DEFAULT_ADDRESS_DATA_CONFIG: AddressDataConfig = {
  streetRequired: true,
  housenumberRequired: true,
  cityRequired: true,
  zipRequired: true,
  countryEditable: false,
};
