import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerServiceStore, CustomerState } from '@nbg-digital/customer-service/domain';

@Component({
  selector: 'nv-customer-details',
  templateUrl: './customer-details.component.html',
})
export class CustomerDetailsComponent {
  public customer$: Observable<CustomerState>;
  public personalDataChangeUrl = 'persoenliche-daten-aendern';

  constructor(private customerServiceStore: CustomerServiceStore) {
    this.customer$ = this.customerServiceStore.state$;
  }
}
