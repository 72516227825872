import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ConsentService } from '@nbg-digital/consent-management/domain';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomerConsentWithdrawalResolver implements Resolve<string | object> {
  constructor(private consentService: ConsentService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot): Observable<string | object> {
    return this.consentService.withdrawCustomerConsent(route.paramMap.get('uuid')).pipe(
      catchError(() => {
        this.router.navigate(['/error']);
        return EMPTY;
      })
    );
  }
}
