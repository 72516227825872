import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerConsentModalComponent } from './customer-consent-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NvButtonModule,
  NvCheckboxModule,
  NvContainerModule,
  NvFormModule,
  NvLinkModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';

@NgModule({
  declarations: [CustomerConsentModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NvContainerModule,
    ReactiveFormsModule,
    NvCheckboxModule,
    NvButtonModule,
    NvFormModule,
    NvModalModule,
    NvLinkModule,
  ],
  exports: [CustomerConsentModalComponent],
})
export class CustomerConsentModalModule {}
