import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationStore, Channel, Decision, PricingParameters } from '@nbg-digital/application/domain';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { Observable, race, Subject } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';

@Component({
  selector: 'nv-application',
  templateUrl: './application.component.html',
})
export class ApplicationComponent implements OnInit {
  @ViewChild('modalPriceChange', { static: true })
  private modalPriceChange: NvModalComponent;

  modalClosed$ = new Subject();
  modalConfirmed$ = new Subject<true>();

  constructor(
    @Inject('channel') private channel: Channel,
    private route: ActivatedRoute,
    private store: ApplicationStore,
    private riskAssessmentFacade: RiskAssessmentFacade
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { pricing: PricingParameters }) => {
      const { pricing } = data;
      // don't set channel when already set from offer-agent
      this.store
        .selectSalesInformation()
        // initial check auf store
        .pipe(take(1))
        .subscribe((salesInfo) => {
          this.store.setSalesInformation({ subBroker: pricing.subBroker });
          this.store.setSalesInformation({ crossInsurance: pricing.consultation?.crossInsurance === Decision.Yes });

          if (!salesInfo?.channel) {
            this.store.setSalesInformation({ channel: this.channel });
          }
          if (!salesInfo?.acquisitionAgent) {
            this.store.setSalesInformation({ acquisitionAgent: pricing.acquisitionAgent });
          }
        });
    });
  }

  openModal(): Observable<boolean> {
    this.modalPriceChange.open();
    return race(this.modalConfirmed$, this.modalClosed$).pipe(
      first(),
      map((e) => !!e),
      tap((confirmed) => {
        if (confirmed) {
          this.riskAssessmentFacade.resetState();
          this.modalPriceChange.dismiss();
        }
      })
    );
  }
}
