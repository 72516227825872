import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CountryService, validateOptions } from '@nbg-digital/shared/util';
import { AutoCompleteOption, PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { map, Observable } from 'rxjs';
import { AddressDataConfig } from './address-data-config';

export interface AddressData {
  city: string;
  country: string;
  street: string;
  housenumber: string;
  zip: string;
}
@Component({
  selector: 'nv-application-address-data',
  templateUrl: './address-data.component.html',
})
export class AddressDataComponent extends PartialFormComponent<AddressData> {
  @Input() set config(config: AddressDataConfig) {
    this._config = config;
    this.applyConfig();
  }

  get config(): AddressDataConfig {
    return this._config;
  }

  private _config: AddressDataConfig;

  countries: Observable<AutoCompleteOption[]> = this.countryService
    .getCountryList()
    .pipe(map((countries) => countries.map((c) => ({ value: c.iso, text: c.country }))));

  constructor(private fBuilder: UntypedFormBuilder, private countryService: CountryService) {
    super();

    this.formGroup = this.fBuilder.group({
      street: '',
      housenumber: '',
      zip: '',
      city: '',
      country: '',
    });
  }

  applyConfig() {
    const controls = this.formGroup.controls;
    controls.country.setValidators(Validators.required);

    controls.street.setValidators(this.config.streetRequired ? [Validators.required] : []);
    controls.housenumber.setValidators(this.config.housenumberRequired ? [Validators.required] : []);
    controls.zip.setValidators(this.config.zipRequired ? [Validators.required, Validators.pattern('^[0-9]{5}')] : []);
    controls.city.setValidators(
      this.config.cityRequired ? [Validators.required, Validators.pattern('[a-zA-ZüöäÜÖÄß/()\\- ]+')] : []
    );
    if (!this.config.countryEditable) {
      this.setReadOnlyCountryValue('DE');
    } else {
      controls.country.setValidators([Validators.minLength(2), Validators.required]);
      controls.country.setAsyncValidators(validateOptions(this.countries));
    }
  }

  private setReadOnlyCountryValue(country: string) {
    this.formGroup.patchValue({ country });
  }

  protected toFormData(value: Partial<AddressData>) {
    if (value == null) {
      return '';
    }
    return {
      ...value,
    };
  }

  protected fromFormData(): AddressData {
    return {
      ...this.formGroup.getRawValue(),
      street: this.formGroup.value.street || undefined,
      housenumber: this.formGroup.value.housenumber || undefined,
      zip: this.formGroup.value.zip || undefined,
      city: this.formGroup.value.city || undefined,
    };
  }
}
