import { AppConfig } from '@nbg-digital/shared/util';

export const APP_CONFIG: AppConfig = {
  showHeaderWithFixedNavigationBar: true,
  showRating: false,
  showBayerischeProgressBar: false,
  progressNavigationAssetsPath: '',
  enableEwe: false,
  showAcquisitionAgentCheckboxes: false,
  enableReentry: true,
  showLoginButtonInFooter: false,
};
