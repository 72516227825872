import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationDomainModule } from '@nbg-digital/application/domain';
import {
  NvCardModule,
  NvCheckboxModule,
  NvErrorModule,
  NvFormModule,
  NvInfoTextContainerModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
  NvWizardModule,
} from '@nbg-digital/ui-dpl-components';
import { PaymentComponent } from './payment.component';
import { PaymentDataModule } from '../../components/payment-data/payment-data.module';

@NgModule({
  imports: [
    ApplicationDomainModule,
    CommonModule,
    NgbModule,
    NvCardModule,
    NvCheckboxModule,
    NvInfoTextContainerModule,
    NvModalModule,
    NvErrorModule,
    NvFormModule,
    NvInputModule,
    NvWizardModule,
    NvLinkModule,
    ReactiveFormsModule,
    TranslateModule,
    NvLinkModule,
    PaymentDataModule,
  ],
  exports: [PaymentComponent],
  declarations: [PaymentComponent],
  providers: [],
})
export class PaymentModule {}
