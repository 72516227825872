import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Contract, ContractService } from '@nbg-digital/customer-service/domain';
import { Observable } from 'rxjs';

@Injectable()
export class ContractDetailsResolver implements Resolve<Contract> {
  constructor(private contractService: ContractService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Contract> {
    return this.contractService.getContractDetails(route.params.product, route.params.contractId);
  }
}
