import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BankService,
  CustomerServiceStore,
  Product,
  ServiceRequestService,
} from '@nbg-digital/customer-service/domain';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { PaymentData, PaymentDataComponent } from '../../components/payment-data/payment-data.component';

@Component({
  selector: 'nv-customer-payment-change',
  templateUrl: './payment-change.component.html',
})
export class PaymentChangeComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentDataComponent) paymentDataComponent: PaymentDataComponent;

  paymentForm: UntypedFormGroup;
  product$: Observable<Product>;
  policyNumber$: Observable<string>;
  initialPaymentData: PaymentData;

  private unsubscribe$ = new Subject<void>();

  @ViewChild('successModal', { static: true })
  private successModal: NvModalComponent;
  @ViewChild('failureModal', { static: true })
  private failureModal: NvModalComponent;

  constructor(
    private fbuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private bankService: BankService,
    private customerServiceStore: CustomerServiceStore
  ) {
    this.product$ = route.params.pipe(map((params) => params.product as Product));
    this.policyNumber$ = route.params.pipe(map((params) => params.contractId));

    this.paymentForm = this.fbuilder.group({
      paymentData: null,
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (!this.paymentForm.valid) {
      this.paymentForm.markAllAsTouched();
    } else {
      const { iban, changeDate } = this.paymentDataComponent.value;

      this.policyNumber$
        .pipe(
          takeUntil(this.unsubscribe$),
          mergeMap((policyNumber) =>
            this.bankService.changeBankData({
              policyNumber,
              iban,
              changeDate,
            })
          ),
          catchError(() => {
            this.failureModal.open();
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.successModal.open();
          this.router.navigate(['../'], { relativeTo: this.route });
        });
    }
  }

  ngOnInit(): void {
    this.customerServiceStore
      .selectCustomer()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.initialPaymentData = {
          ...this.initialPaymentData,
          firstname: data.firstname,
          lastname: data.lastname,
        };
      });
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.paymentForm.setControl(name, form);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
