import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NvButtonModule, NvCardModule } from '@nbg-digital/ui-dpl-components';
import { MessagePreviewComponent } from './message-preview.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MessagePreviewComponent],
  imports: [CommonModule, TranslateModule, NvButtonModule, NvCardModule, RouterModule],
  exports: [MessagePreviewComponent],
})
export class MessagePreviewModule {}
