import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  NvButtonModule,
  NvCardModule,
  NvCheckboxModule,
  NvFormModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { ContractOverviewComponent } from './contract-overview.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SendMessageModule } from '../send-message/send-message.module';

@NgModule({
  imports: [
    CommonModule,
    NvButtonModule,
    NvCardModule,
    NvCheckboxModule,
    NvFormModule,
    NvModalModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    SendMessageModule,
  ],
  declarations: [ContractOverviewComponent],
  exports: [ContractOverviewComponent],
})
export class ContractOverviewModule {}
