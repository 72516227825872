import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewFeaturesModalComponent } from './new-features-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NvButtonModule, NvModalModule } from '@nbg-digital/ui-dpl-components';

@NgModule({
  declarations: [NewFeaturesModalComponent],
  imports: [CommonModule, TranslateModule, NgbCarouselModule, NvModalModule, NvButtonModule],
  exports: [NewFeaturesModalComponent],
})
export class NewFeaturesModalModule {}
