import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentCompleteGuard implements CanActivate, CanActivateChild {
  constructor(
    private riskAssessmentFacade: RiskAssessmentFacade,
    private router: Router,
    @Inject('riskAssessmentPath') private riskAssessmentPath: string
  ) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this.riskAssessmentFacade.isRiskAssessmentComplete$.pipe(
      map((isComplete) => isComplete || this.router.createUrlTree([`/${this.riskAssessmentPath}`]))
    );
  }
  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
