import { Component, Input } from '@angular/core';

@Component({
  selector: 'nv-kupo-header',
  templateUrl: './kupo-header.component.html',
  styleUrls: ['./kupo-header.component.scss'],
})
export class KupoHeaderComponent {
  @Input() logoHref = '';
  public showMenu = false;

  toggleMenu(showMenu?: boolean) {
    if (showMenu !== undefined) {
      this.showMenu = showMenu;
    } else {
      this.showMenu = !this.showMenu;
    }
  }
}
