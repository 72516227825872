import { Component, Input, ViewChild } from '@angular/core';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceRequestData, ServiceRequestService, ServiceRequestSubject } from '@nbg-digital/customer-service/domain';
import { catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Observer, of } from 'rxjs';
import heic2any from 'heic2any';

interface FilePreview {
  src: string | ArrayBuffer;
  fileName: string;
  fileExtension: string;
}

@Component({
  selector: 'nv-customer-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.scss'],
})
export class SendMessageComponent {
  files$ = new BehaviorSubject<File[]>([]);
  filePreviews$: Observable<FilePreview[]>;
  sendMessageForm: UntypedFormGroup;

  private readonly pdfIconUrl = 'assets/images/file-pdf-thin.svg';

  constructor(private fBuilder: UntypedFormBuilder, private serviceRequestService: ServiceRequestService) {
    this.sendMessageForm = this.fBuilder.group({
      message: [null, Validators.required],
      files: '',
    });
    this.filePreviews$ = this.files$.pipe(switchMap((files) => this.createFilePreview(files)));
  }

  @ViewChild('sendMessageModal', { static: true })
  private sendMessageModal: NvModalComponent;
  @ViewChild('successModal', { static: true })
  private successModal: NvModalComponent;
  @ViewChild('failureModal', { static: true })
  private failureModal: NvModalComponent;

  @Input() policyNumber;

  openMessageModal() {
    this.resetMessageForm();
    this.sendMessageModal.open();
  }

  buildFormData() {
    const formData = new FormData();

    const serviceRequestData: ServiceRequestData = {
      policyNumber: this.policyNumber,
      subject: ServiceRequestSubject.Other,
      message: this.sendMessageForm.controls['message'].value,
    };

    formData.append(
      'serviceRequest',
      new Blob(
        [
          JSON.stringify({
            policyNumber: serviceRequestData.policyNumber,
            subject: serviceRequestData.subject,
            message: serviceRequestData.message,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    );

    formData.delete('documents');

    if (this.files$) {
      for (const file of this.files$.getValue()) {
        formData.append('documents', file, file.name);
      }
    }

    return formData;
  }

  submitSendMessage(event: Event) {
    this.sendMessageForm.markAllAsTouched();

    const formData = this.buildFormData();

    event.preventDefault();
    if (this.sendMessageForm.valid) {
      this.serviceRequestService
        .sendMessage(formData)
        .pipe(
          catchError(() => {
            this.failureModal.open();
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.sendMessageModal.close();
          this.successModal.open();
        });
    }
  }

  async onFileSelection(files) {
    const dataTransfer = new DataTransfer();
    await this.convertHeicImages(dataTransfer, files);
    this.files$.next(this.addNewFiles(dataTransfer, this.files$.getValue()));
    this.validateFileSizes(this.files$.getValue());
  }

  removeFile(fileName: string) {
    const files = this.files$.getValue();
    const index = files.findIndex((file) => file.name === fileName);
    this.files$.next(files.remove(files[index]));
    this.validateFileSizes(this.files$.getValue());
  }

  resetFileInput() {
    this.sendMessageForm.get('files').reset();
    this.validateFileSizes(this.files$.getValue());
  }

  // TODO: This probably needs a loading spinner
  private async convertHeicImages(dataTransfer, files) {
    for (const file of files.target.files) {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const nameOfFile = file.name;
        const jpeg = (await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.5 })) as Blob;
        const convertedFile = new File([jpeg], nameOfFile, {
          type: jpeg.type,
        });
        dataTransfer.items.add(convertedFile);
      } else {
        dataTransfer.items.add(file);
      }
    }
    return dataTransfer;
  }

  private addNewFiles(dataTransfer: DataTransfer, existingFiles: File[]) {
    for (let i = 0; i < dataTransfer.files.length; i++) {
      const file = dataTransfer.files[i];
      // if a name with the same name has already been selected, skip it
      if (existingFiles.some((f) => f.name === file.name)) {
        continue;
      }

      existingFiles.push(file);
    }
    return existingFiles;
  }

  private validateFileSizes(files: File[]) {
    const maxUploadSize = 5 * 1024 * 1024;
    let uploadSize = 0;

    for (const file of files) {
      uploadSize += file.size;
    }

    if (uploadSize > maxUploadSize) {
      this.sendMessageForm.controls.files.setErrors({ fileSizeExceeded: true });
      this.sendMessageForm.controls.files.markAsTouched();
    } else {
      this.sendMessageForm.controls.files.setErrors(null);
    }
  }

  private createFilePreview(files: File[]): Observable<FilePreview[]> {
    const previews$ = files?.map((file) => {
      return new Observable((observer: Observer<FilePreview>) => {
        const reader = new FileReader();
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts.pop();
        const fileName = fileNameParts.join('.');

        if (file.type === 'application/pdf') {
          observer.next({ src: this.pdfIconUrl, fileName, fileExtension });
          observer.complete();
        } else {
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            observer.next({ src: event.target.result, fileName, fileExtension });
            observer.complete();
          };
        }
      });
    });
    if (previews$?.length) {
      return combineLatest(previews$);
    }
    return of([]);
  }

  private resetMessageForm() {
    this.sendMessageForm.reset();
    this.files$.next([]);
  }
}
