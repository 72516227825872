import { Component, Input } from '@angular/core';
import { PolicyInformationRlv } from '@nbg-digital/customer-service/domain';

@Component({
  selector: 'nv-customer-policy-specifics-rlv',
  templateUrl: './policy-specifics-rlv.component.html',
})
export class PolicySpecificsRlvComponent {
  @Input() policyInformation: PolicyInformationRlv;
}
