import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { isIbanValid } from '@nbg-digital/shared/util';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import { BankService } from '@nbg-digital/application/domain';

export interface PaymentData {
  bic: string;
  iban: string;
  accountNumber: string;
  bankCode: string;
  bankName?: string;
  mandate: boolean;
  firstname: string;
  lastname: string;
}

type PaymentInputMode = 'iban' | 'accountNumber';

@Component({
  selector: 'nv-application-payment-data',
  templateUrl: './payment-data.component.html',
})
export class PaymentDataComponent extends PartialFormComponent<PaymentData> implements OnDestroy, OnInit {
  @Input() isRequired = true;
  @Input() showName = true;

  inputMode: PaymentInputMode = 'iban';

  private ibanValidators = [Validators.required, isIbanValid];
  private accountNumberValidators = [Validators.required, Validators.pattern('[0-9]{10}')];
  private bankCodeValidators = [Validators.required, Validators.pattern('[0-9]{8}')];

  protected unsubscribe$ = new Subject<void>();

  constructor(private fBuilder: UntypedFormBuilder, private bankService: BankService) {
    super();

    this.formGroup = this.fBuilder.group({
      bic: '',
      firstname: '',
      iban: '',
      accountNumber: '',
      bankCode: '',
      bankName: '',
      lastname: '',
      mandate: '',
    });

    this.formGroup
      .get('iban')
      .valueChanges.pipe(
        map((iban) => {
          this.formGroup.patchValue({ iban: iban.toUpperCase() }, { emitEvent: false });
          return iban.toUpperCase();
        }),
        mergeMap((iban) => {
          if (this.formGroup.controls.iban.valid && this.formGroup.controls.iban.value != '') {
            return this.bankService.getInfo(iban);
          }
          return of({ bic: '', bankName: '' });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((bankInfo) => {
        this.formGroup.patchValue({ bic: bankInfo.bic, bankName: bankInfo.bankName });
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.setValidation();

    combineLatest([this.formGroup.get('accountNumber').valueChanges, this.formGroup.get('bankCode').valueChanges])
      .pipe(
        mergeMap(([accountNumber, bankCode]) => {
          if (this.formGroup.get('accountNumber').valid && this.formGroup.get('bankCode').valid) {
            return this.bankService.getAccountInfo(accountNumber, bankCode);
          }
          return of({ iban: '', bic: '', bankName: '' });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ iban, bic, bankName }) => {
        this.formGroup.patchValue({ iban, bic, bankName }, { emitEvent: false });
      });
  }

  setValidation() {
    const controls = this.formGroup.controls;
    controls.bic.disable();
    controls.bankName.disable();
    controls.firstname.disable();
    controls.lastname.disable();

    controls.mandate.setValidators(this.isRequired ? [Validators.requiredTrue] : []);
    controls.iban.setValidators(this.isRequired ? this.ibanValidators : [isIbanValid]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleInputMode(event: MouseEvent, inputMode: PaymentInputMode) {
    event.preventDefault();
    this.inputMode = inputMode;
    const ibanControl = this.formGroup.get('iban');
    const accountNumberControl = this.formGroup.get('accountNumber');
    const bankCodeControl = this.formGroup.get('bankCode');

    if (this.isRequired) {
      if (inputMode === 'iban') {
        ibanControl.setValidators(this.isRequired ? this.ibanValidators : [isIbanValid]);
        accountNumberControl.setValue('');
        bankCodeControl.setValue('');
        accountNumberControl.clearValidators();
        bankCodeControl.clearValidators();
        accountNumberControl.setErrors(null);
        bankCodeControl.setErrors(null);
      } else {
        bankCodeControl.setValidators(this.isRequired ? this.bankCodeValidators : []);
        ibanControl.setValue('');
        ibanControl.clearValidators();
        accountNumberControl.setValidators(this.accountNumberValidators);
        this.formGroup.updateValueAndValidity();
      }
    }
  }

  protected fromFormData(): PaymentData {
    return {
      ...this.formGroup.getRawValue(),
    };
  }

  protected toFormData(value: Partial<PaymentData>) {
    if (value == null) {
      return '';
    }
    return {
      ...value,
    };
  }
}
