import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BeneficiaryType,
  Contract,
  ContractService,
  CustomerServiceStore,
  ContractStatus,
  MainBroker,
  PolicyInformation,
  PolicyInformationDkr,
  PolicyInformationEks,
  PolicyInformationRlv,
  Product,
  ServiceRequestService,
  TerminationOption,
} from '@nbg-digital/customer-service/domain';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isDateFirstOfMonth } from '@nbg-digital/shared/util';

dayjs.extend(utc);

@Component({
  selector: 'nv-customer-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('modalContractRevocation', { static: true })
  private modalContractRevocation: NvModalComponent;

  @ViewChild('modalContractTermination', { static: true })
  private modalContractTermination: NvModalComponent;

  @ViewChild('successModal', { static: true })
  private successModal: NvModalComponent;

  public Product = Product;
  public TerminationOption = TerminationOption;
  public ContractStatus = ContractStatus;
  public terminationOption: TerminationOption;
  private unsubscribe$ = new Subject<void>();
  public contract: Contract;

  public minDate: string;
  public maxDate: string;

  dateTerminationForm: UntypedFormGroup;

  public overview = 'uebersicht';
  public BeneficiaryType = BeneficiaryType;

  constructor(
    private route: ActivatedRoute,
    private store: CustomerServiceStore,
    private fBuilder: UntypedFormBuilder,
    private contractService: ContractService,
    private serviceRequestService: ServiceRequestService
  ) {
    this.dateTerminationForm = this.fBuilder.group({
      terminationDate: ['', [Validators.required, isDateFirstOfMonth]],
    });
  }

  asPolicyInformationRlv(policyInformation: PolicyInformation): PolicyInformationRlv {
    return policyInformation as PolicyInformationRlv;
  }

  asPolicyInformationDkr(policyInformation: PolicyInformation): PolicyInformationDkr {
    return policyInformation as PolicyInformationDkr;
  }

  asPolicyInformationEks(policyInformation: PolicyInformation): PolicyInformationEks {
    return policyInformation as PolicyInformationEks;
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.contract = data.contractDetails;
    });

    this.minDate = dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    this.maxDate = dayjs(this.contract.policyInformation.insuranceEnd).startOf('month').format('YYYY-MM-DD');

    this.dateTerminationForm.patchValue({ terminationDate: this.minDate });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isNonDefaultMainBroker() {
    return this.contract.mainBroker && this.contract.mainBroker !== MainBroker.Evox;
  }

  isDefaultMainBroker() {
    return this.contract.mainBroker && this.contract.mainBroker === MainBroker.Evox;
  }

  openTerminationModal() {
    this.modalContractTermination.open();
  }

  submitTermination(event: Event) {
    event.preventDefault();
    this.dateTerminationForm.markAllAsTouched();
    if (this.dateTerminationForm.valid) {
      this.serviceRequestService
        .terminateContract(this.dateTerminationForm.controls['terminationDate'].value, this.contract.policyNumber)
        .subscribe(() => {
          this.modalContractTermination.close();
          this.terminationOption = TerminationOption.Termination;
          this.successModal.open();
          this.reloadContractDetails();
          this.store.resetContracts();
        });
    }
  }

  openRevocationModal() {
    this.modalContractRevocation.open();
  }

  submitRevocation(event: Event) {
    event.preventDefault();
    this.serviceRequestService.revokeContract(this.contract.policyNumber).subscribe(() => {
      this.modalContractRevocation.close();
      this.terminationOption = TerminationOption.Revocation;
      this.successModal.open();
      this.reloadContractDetails();
      this.store.resetContracts();
    });
  }

  downloadPolicy(event: Event) {
    event.preventDefault();
    this.contractService.getContractDocuments(this.contract.policyNumber).subscribe();
  }

  private reloadContractDetails() {
    this.contractService
      .getContractDetails(
        this.route.snapshot.paramMap.get('product') as Product,
        this.route.snapshot.paramMap.get('contractId')
      )
      .subscribe((contract) => (this.contract = contract));
  }
}
