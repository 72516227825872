import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalDetailsComponent } from './personal-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NvAutoCompleteModule, NvDropdownModule, NvErrorModule, NvInputModule } from '@nbg-digital/ui-dpl-components';
import { BirthdateModule } from '../birthdate/birthdate.module';

@NgModule({
  declarations: [PersonalDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NvAutoCompleteModule,
    BirthdateModule,
    NvDropdownModule,
    NvErrorModule,
    NvInputModule,
  ],
  exports: [PersonalDetailsComponent],
})
export class PersonalDetailsModule {}
