import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nv-kupo-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private userFacade: UserManagementFacade) {}

  ngOnInit() {
    this.userFacade.signOut().pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
