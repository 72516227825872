import { Component, OnInit } from '@angular/core';
import { CustomerServiceStore, Message, MessageService, MessageType } from '@nbg-digital/customer-service/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nv-customer-message-preview',
  templateUrl: 'message-preview.component.html',
  styleUrls: ['./message-preview.component.scss'],
})
export class MessagePreviewComponent implements OnInit {
  messages$: Observable<Message[]>;
  MessageType = MessageType;
  postboxUrl = '/postfach';
  messageCount = 3;

  constructor(private customerServiceStore: CustomerServiceStore, private messageService: MessageService) {}

  ngOnInit() {
    this.messageService
      .getNewestMessages(this.messageCount)
      .pipe(
        map((res) => {
          this.customerServiceStore.update({ messages: res.messages });
        })
      )
      .subscribe(() => {
        this.messages$ = this.customerServiceStore.selectMessages();
      });
  }
}
