import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomPipesModule, ModuleWithTranslations } from '@nbg-digital/shared/util';
import {NvButtonModule, NvCardModule, NvCollapseModule, NvLinkModule} from '@nbg-digital/ui-dpl-components';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageComponent } from './message/message.component';
import { PostboxDetailsComponent } from './postbox-details.component';
import { PostboxDetailsResolver } from './postbox-details.resolver';

export function createPostboxDetailsTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/postbox-details/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    NvButtonModule,
    NvCardModule,
    NvCollapseModule,
    CustomPipesModule,
    RouterModule.forChild([
      {
        path: '',
        component: PostboxDetailsComponent,
        resolve: { messages: PostboxDetailsResolver },
      },
      {
        path: ':messageId',
        component: PostboxDetailsComponent,
        resolve: { messages: PostboxDetailsResolver },
      },
    ]),

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createPostboxDetailsTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
    NvLinkModule,
  ],
  exports: [],
  declarations: [PostboxDetailsComponent, MessageComponent],
  providers: [],
})
export class PostboxDetailsModule extends ModuleWithTranslations {
  constructor(private translateService: TranslateService) {
    super(translateService);
  }
}
