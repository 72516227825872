import { Injectable, Inject } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserManagementFacade } from '@nbg-digital/user-management/api';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userManagementFacade: UserManagementFacade,
    private router: Router,
    @Inject('loginPath') private loginPath: string
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userManagementFacade.isAuthenticated || this.router.createUrlTree([`/${this.loginPath}`]);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthIllegalGuard implements CanActivate {
  constructor(private userManagementFacade: UserManagementFacade, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.userManagementFacade.isAuthenticated || this.router.createUrlTree(['/']);
  }
}
