import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationDomainModule } from '@nbg-digital/application/domain';
import { PersonalDataComponent } from './personal-data.component';
import {
  NvAutoCompleteModule,
  NvCardModule,
  NvCheckboxModule,
  NvDropdownModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvWizardModule,
} from '@nbg-digital/ui-dpl-components';
import { PersonalDetailsModule } from '../../components/personal-details/personal-details.module';
import { AddressDataModule } from '../../components/address-data/address-data.module';
import { EmailModule } from '../../components/email/email.module';

@NgModule({
  imports: [
    ApplicationDomainModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    AddressDataModule,
    EmailModule,
    PersonalDetailsModule,
    NvAutoCompleteModule,
    NvCardModule,
    NvCheckboxModule,
    NvDropdownModule,
    NvErrorModule,
    NvFormModule,
    NvInputModule,
    NvWizardModule,
  ],
  declarations: [PersonalDataComponent],
  exports: [PersonalDataComponent],
})
export class PersonalDataModule {}
