import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map, Observable } from 'rxjs';

import { Role, Salutation } from '@nbg-digital/application/domain';
import { CountryService, validateOptions } from '@nbg-digital/shared/util';
import { AutoCompleteOption, PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { BirthdateComponent } from '../birthdate/birthdate.component';
import { PersonalDetailsConfig } from './personal-details-config';
import { hasCorrectLength } from '@nbg-digital/shared/util';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export interface PersonalDetails {
  salutation: Salutation;
  firstname: string;
  lastname: string;
  birthDate: string;
  phone?: string;
  cityOfBirth: string;
  nationality?: string;
}

@Component({
  selector: 'nv-application-personal-details',
  templateUrl: './personal-details.component.html',
})
export class PersonalDetailsComponent extends PartialFormComponent<PersonalDetails> {
  @ViewChild(BirthdateComponent) birthDateComponent: BirthdateComponent;

  @Input() set value(value: Partial<PersonalDetails>) {
    super.value = value;
    this.initialBirthdate = value.birthDate;
  }

  get value(): PersonalDetails {
    return super.value;
  }

  @Input() role: Role = Role.Insuree;

  @Input() set config(config: PersonalDetailsConfig) {
    this._config = config;
    this.applyConfig();
  }

  get config(): PersonalDetailsConfig {
    return this._config;
  }

  private _config: PersonalDetailsConfig;

  salutationOptions = Object.values(Salutation);
  initialBirthdate: string;

  nationalities: Observable<AutoCompleteOption[]> = this.countryService
    .getCountryList()
    .pipe(map((countries) => countries.map((c) => ({ value: c.iso, text: c.nationality }))));

  constructor(private fbuilder: UntypedFormBuilder, private countryService: CountryService) {
    super();

    this.formGroup = this.fbuilder.group({
      salutation: '',
      firstname: '',
      lastname: '',
      birthDate: null,
      phone: '',
      cityOfBirth: '',
      nationality: '',
    });
  }

  applyConfig() {
    const controls = this.formGroup.controls;
    controls.salutation.setValidators(Validators.required);
    controls.phone.setValidators(
      this.config.phoneRequired
        ? [hasCorrectLength(), Validators.pattern('(01[5-7]\\d+$)|(^\\+[1-9]\\d{1,14}$)'), Validators.required]
        : []
    );

    if (!this.config.phoneRequired) {
      controls.phone.setValidators([hasCorrectLength(), Validators.pattern('(01[5-7]\\d+$)|(^\\+[1-9]\\d{1,14}$)')]);
    }

    controls.firstname.setValidators(this.config.nameRequired ? [Validators.required] : []);
    controls.lastname.setValidators(this.config.nameRequired ? [Validators.required] : []);
    controls.cityOfBirth.setValidators(this.config.cityOfBirthRequired ? [Validators.required] : []);
    controls.nationality.setValidators(
      this.config.nationalityRequired ? [Validators.minLength(2), Validators.required] : []
    );
    controls.nationality.setAsyncValidators(validateOptions(this.nationalities));

    this.config.salutationEditable ? controls.salutation.enable() : controls.salutation.disable();
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.formGroup.setControl(name, form);
  }

  protected toFormData(value: PersonalDetails) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { birthDate, ...details } = value;
    return details;
  }

  protected fromFormData(): PersonalDetails {
    return {
      ...this.formGroup.getRawValue(),
      phone: this.formGroup.value.phone
        ? this.getInternationalFormattedPhoneNumber(this.formGroup.value.phone)
        : undefined,
      birthDate: this.birthDateComponent?.value || this.initialBirthdate,
      firstname: this.formGroup.value.firstname || undefined,
      lastname: this.formGroup.value.lastname || undefined,
      cityOfBirth: this.formGroup.value.cityOfBirth || undefined,
    };
  }

  getInternationalFormattedPhoneNumber(phone: string): string {
    if (isValidPhoneNumber(phone, 'DE')) {
      const parsedNumber = parsePhoneNumber(phone, 'DE');
      if (parsedNumber) {
        return parsedNumber.number;
      }
    }
    return phone;
  }
}
