import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentDownloadComponent } from './consent-download/consent-download.component';
import { NvButtonModule, NvCardModule, NvContainerModule } from '@nbg-digital/ui-dpl-components';

const routes: Routes = [
  {
    path: '',
    component: ConsentDownloadComponent,
  },
];

@NgModule({
  declarations: [ConsentDownloadComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NvCardModule,
    NvButtonModule,
    NvContainerModule,
  ],
})
export class ConsentManagementDownloadModule {}
