import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  NavigationBarModule,
  NvButtonModule,
  NvCardModule,
  NvConstructionPageModule,
  NvContainerModule,
  NvFooterModule,
  NvHeaderModule,
  NvLoadingSpinnerModalModule,
} from '@nbg-digital/ui-dpl-components';
import { AppComponent } from './app.component';
import { AppRoutingModule, ERROR_PATH, LOGIN_PATH, PASSWORD_FORGOT_PATH } from './app-routing.module';
import { environment } from '../environments/environment';
import { CredentialsInterceptor } from './auth/credentials.interceptor';
import { ErrorComponent } from './error/error.component';
import { ErrorInterceptor } from './error/error-interceptor';
import {
  AppConfigModule,
  HttpLoaderFactory,
  LoaderInterceptor,
  ProductService,
  ProductServiceFactory,
} from '@nbg-digital/shared/util';
import { LoginDataModule, LogOutButtonModule } from '@nbg-digital/user-management/feature';
import { ContractOverviewModule } from '@nbg-digital/customer-service/feature';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ProtectedAreaComponent } from './protected-area/protected-area.component';
import { KupoHeaderComponent } from './components/kupo-header/kupo-header.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CustomerConsentModalModule } from '@nbg-digital/consent-management/feature';
import { NewFeaturesModalModule } from './components/new-features-modal/new-features-modal.module';
import { OverviewModule } from './pages/overview/overview.module';
import { APP_CONFIG } from './app-config';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, ErrorComponent, ProtectedAreaComponent, ProfileComponent, KupoHeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
      useDefaultLang: true,
    }),
    NvHeaderModule,
    NvCardModule,
    NvContainerModule,
    NvConstructionPageModule,
    NvButtonModule,
    NavigationBarModule,
    NvFooterModule,
    LogOutButtonModule,
    LoginDataModule,
    ContractOverviewModule,
    NvLoadingSpinnerModalModule,
    CustomerConsentModalModule,
    NewFeaturesModalModule,
    OverviewModule,
    AppConfigModule.forRoot(APP_CONFIG),
    NgbCollapseModule,
  ],
  providers: [
    { provide: 'userApiUrl', useValue: environment.userApiUrl },
    { provide: 'loginPath', useValue: LOGIN_PATH },
    { provide: 'errorPath', useValue: ERROR_PATH },
    { provide: 'passwordForgotPath', useValue: PASSWORD_FORGOT_PATH },
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl,
    },
    {
      provide: 'landingPageUrl',
      useValue: environment.landingPageUrl,
    },
    {
      provide: 'baseUrl',
      useValue: environment.baseUrl,
    },
    {
      provide: 'eweApiUrl',
      useValue: environment.eweApiUrl,
    },
    {
      provide: 'mainBroker',
      useValue: 'EVOX',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: 'enableConstructionPage',
      useValue: environment.enableConstructionPage,
    },
    {
      provide: ProductService,
      useFactory: ProductServiceFactory,
      deps: ['product'],
    },
    {
      provide: 'product',
      useValue: '',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
