import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavigationItem } from '@nbg-digital/ui-dpl-components';
import { Observable } from 'rxjs';

@Component({
  selector: 'nv-kupo-protected-area',
  templateUrl: './protected-area.component.html',
})
export class ProtectedAreaComponent {
  public isDesktop$ = this.bpObserver
    .observe(`(min-width: 1024px)`)
    .pipe(map((state: BreakpointState) => state.matches));

  public items$: Observable<NavigationItem[]> = this.isDesktop$.pipe(
    map((isDesktop: boolean) => {
      return [
        {
          id: 'overview-button',
          route: 'uebersicht',
          icon: 'fal fa-home fa-lg',
          title: 'APP.NAVIGATION.OVERVIEW',
        },
        {
          id: 'mail-button',
          route: 'postfach',
          icon: 'fal fa-envelope fa-lg',
          title: 'APP.NAVIGATION.MAILBOX',
        },
        {
          id: 'profile-button',
          route: isDesktop ? 'profil/persoenliche-daten' : 'profil',
          icon: 'fal fa-user-alt fa-lg',
          title: 'APP.NAVIGATION.PROFILE',
        },
      ];
    })
  );

  constructor(private bpObserver: BreakpointObserver) {}
}
