import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CustomerServiceStore, Message, MessageService } from '@nbg-digital/customer-service/domain';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PostboxDetailsResolver implements Resolve<Message[]> {
  constructor(private messageService: MessageService, private store: CustomerServiceStore) {}

  resolve(): Observable<Message[]> {
    return this.messageService.getMessages().pipe(
      map((res) => res.messages),
      tap((messages) => this.store.update({ messages }))
    );
  }
}
