import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ApplicationStore, InsuranceObjectType, Role } from '@nbg-digital/application/domain';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { CustomerInformationFacade } from '@nbg-digital/customer-service/api';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { EMPTY } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class CustomerDataResolver implements Resolve<any> {
  constructor(
    private userManagementFacade: UserManagementFacade,
    private customerInformationFacade: CustomerInformationFacade,
    private pricingFacade: PricingFacade,
    private riskAssessmentFacade: RiskAssessmentFacade,
    private applicationStore: ApplicationStore,
    private router: Router
  ) {}

  resolve() {
    return this.customerInformationFacade.getCustomerInformation().pipe(
      withLatestFrom(this.pricingFacade.selectedBirthdate$, this.pricingFacade.insureeSummary$),
      switchMap(([customerInformationResponse, currentBirthDate, insureeSummary]) => {
        // if user has already finished application, do not route back anywhere
        if (this.router.url.includes('abschluss/erfolg')) {
          return EMPTY;
        }
        // if the birthdate is different than the birthdate from user data, clear the risk assessment so the
        // user has to go to the pricing again
        const isBirthdateChanged = customerInformationResponse.personalInformation.birthDate !== currentBirthDate;
        const isSalutationChanged =
          customerInformationResponse.personalInformation.salutation !== insureeSummary.salutation;
        this.pricingFacade.setBirthDate(customerInformationResponse.personalInformation.birthDate);
        this.pricingFacade.setSalutation(customerInformationResponse.personalInformation.salutation);
        this.applicationStore.setPaymentInfo(customerInformationResponse.paymentInformation);
        this.applicationStore.setInsuranceObject(this.createInsuranceObject(customerInformationResponse));
        this.userManagementFacade.resetRegistrationState();

        // if birthdate has changed navigate back to the first page
        if (isBirthdateChanged || isSalutationChanged) {
          this.riskAssessmentFacade.resetState();
          this.router.navigate(['tarifierung']);
          return EMPTY;
        }
        // if user was already in application or later, navigate to the first application page
        else if (this.router.url.includes('antrag') || this.router.url.includes('abschluss')) {
          this.router.navigate(['antrag']);
          return EMPTY;
        }
        // otherwise stay at the same page as login happened
        return EMPTY;
      })
    );
  }

  private createInsuranceObject(customerInformationResponse: any) {
    const {
      city,
      street,
      country,
      zip,
      houseNumber: housenumber,
      firstname,
      lastname,
      salutation,
      birthDate,
      phone,
      email,
      nationality,
      cityOfBirth,
    } = customerInformationResponse.personalInformation;

    return {
      type: InsuranceObjectType.Person,
      roles: [Role.PolicyHolder, Role.Insuree, Role.PremiumPayer],
      address: {
        city,
        street,
        country,
        zip,
        housenumber,
      },
      firstname,
      lastname,
      salutation,
      birthDate,
      phone,
      email,
      nationality,
      cityOfBirth,
    };
  }
}
