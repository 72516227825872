import { Component, Inject, Injectable, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { AbstractControl, AsyncValidator, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { emailPattern } from '@nbg-digital/shared/util';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserManagementFacade } from '@nbg-digital/user-management/api';

@Injectable({ providedIn: 'root' })
class UniqueEmailValidator implements AsyncValidator {
  constructor(
    private userManagementFacade: UserManagementFacade,
    @Inject('enableUserLogin') private enableUserLogin: boolean
  ) {}
  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (
      ctrl.hasError('pattern') ||
      ctrl.hasError('required') ||
      (this.userManagementFacade.isAuthenticated && this.enableUserLogin)
    ) {
      return of(null);
    }

    return this.userManagementFacade.checkEmailAvailability(ctrl.value).pipe(
      catchError((error) => {
        if (error['status'] === 409) {
          return of(false);
        }
        throw error;
      }),
      map((isAvailable) => (isAvailable ? null : { alreadyExists: true }))
    );
  }
}

@Component({
  selector: 'nv-application-email',
  templateUrl: './email.component.html',
})
export class EmailComponent extends PartialFormComponent<string> implements OnInit, OnChanges {
  @Input() readOnly?: boolean;
  @Input() checkAvailability = true;

  private validators = [Validators.required, emailPattern];

  constructor(private fBuilder: UntypedFormBuilder, private uniqueEmailValidator: UniqueEmailValidator) {
    super();

    this.formGroup = this.fBuilder.group({
      email: [
        '',
        {
          validators: this.validators,
          asyncValidators: [uniqueEmailValidator],
        },
      ],
    });
  }

  ngOnInit() {
    if (!this.checkAvailability) {
      this.formGroup.get('email').clearAsyncValidators();
    }
    super.ngOnInit();
  }

  setEmailValidators() {
    this.formGroup.get('email').setValidators(this.validators);
    this.formGroup.get('email').updateValueAndValidity();
  }

  resetEmailValidators() {
    this.formGroup.get('email').clearValidators();
    this.formGroup.get('email').updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readOnly && !changes.readOnly.firstChange) {
      this.formGroup.get('email').updateValueAndValidity();
    }
  }

  protected fromFormData(): string {
    return this.formGroup.value.email;
  }

  protected toFormData(value: string) {
    if (value == null) {
      return '';
    }
    return {
      email: value,
    };
  }
}
