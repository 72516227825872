import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { isNoFutureDate, isValidDateFormat, maxAge, minAge } from '@nbg-digital/shared/util';
import { PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
  selector: 'nv-application-birthdate',
  templateUrl: './birthdate.component.html',
})
export class BirthdateComponent extends PartialFormComponent<string> implements OnInit {
  @Input() validateInsurableAge = false;

  minAge = 0;
  maxAge = 150;

  private dateFormat = 'DD.MM.YYYY';
  private isoFormat = 'YYYY-MM-DD';
  private dateSeparator = '.';

  constructor(private fBuilder: UntypedFormBuilder) {
    super();
    this.formGroup = this.fBuilder.group({
      birthDate: [''],
    });
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.validateInsurableAge) {
      this.minAge = 18;
      this.maxAge = 65;
    }

    this.formGroup.controls['birthDate'].setValidators([
      Validators.required,
      minAge(this.minAge, this.dateFormat),
      maxAge(this.maxAge, this.dateFormat),
      isValidDateFormat(this.dateFormat),
      isNoFutureDate(this.dateFormat),
    ]);

    this.formGroup.controls['birthDate'].updateValueAndValidity();
  }

  onInput(event: Event) {
    this.pretypeDateSeparators(event as InputEvent);
  }

  protected toFormData(value: string) {
    return { birthDate: value ? dayjs(value, this.isoFormat).format(this.dateFormat) : value };
  }

  protected fromFormData(): string {
    const value = this.formGroup.value.birthDate;
    return value ? dayjs(value, this.dateFormat).format(this.isoFormat) : null;
  }

  private pretypeDateSeparators(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (this.dateFormat[inputElement.value.length] === this.dateSeparator && !!event.data) {
      inputElement.value += '.';
    }
  }
}
