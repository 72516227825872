import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ApplicationComponent } from './application.component';
import { NvModalModule, NvButtonModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ApplicationComponent],
  imports: [CommonModule, RouterModule, NvModalModule, NvButtonModule, TranslateModule],
  exports: [ApplicationComponent],
})
export class ApplicationModule {}
