import { Component, Input, OnInit } from '@angular/core';
import { PolicyInformationEks } from '@nbg-digital/customer-service/domain';

@Component({
  selector: 'nv-customer-policy-specifics-eks',
  templateUrl: './policy-specifics-eks.component.html',
})
export class PolicySpecificsEksComponent implements OnInit {
  @Input() policyInformation: PolicyInformationEks;

  modules: string[] = [];

  ngOnInit() {
    this.modules = [];
    ['office', 'mobility', 'mind'].forEach((module) => {
      if (this.policyInformation?.[module]) {
        this.modules.push(module.toUpperCase());
      }
    });
  }
}
