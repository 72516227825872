import { Component, OnDestroy, ViewChild } from '@angular/core';
import { combineLatest, of, Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConsentService, Salutation } from '@nbg-digital/consent-management/domain';
import { CustomerServiceFacade } from '@nbg-digital/customer-service/api';

@Component({
  selector: 'nv-consent-management-customer-modal',
  templateUrl: './customer-consent-modal.component.html',
})
export class CustomerConsentModalComponent implements OnDestroy {
  @ViewChild('eweModal', { static: true })
  private eweModal: NvModalComponent;

  private unsubscribe$ = new Subject<void>();
  private customerEwePopupTimerInMilSec = 120000;

  formGroup: UntypedFormGroup;
  isSubscribedEmail = false;
  isSubscribedPhone = false;
  eweLink = 'https://www.nuernberger.de/impressum/';

  constructor(
    private userManagementFacade: UserManagementFacade,
    private customerServiceFacade: CustomerServiceFacade,
    private consentService: ConsentService,
    private fBuilder: UntypedFormBuilder
  ) {
    this.formGroup = this.fBuilder.group({
      subscribeEmail: false,
      subscribePhone: false,
    });

    this.userManagementFacade.getUser().subscribe((value) => {
      this.userManagementFacade.setUser(value);
    });

    this.userManagementFacade
      .getUserId()
      .pipe(
        switchMap((value) => this.consentService.getCustomerConsent(value)),
        switchMap((res) => {
          if (!res.subscribedEmail || !res.subscribedPhone) {
            this.isSubscribedEmail = res.subscribedEmail;
            this.isSubscribedPhone = res.subscribedPhone;
            return timer(this.customerEwePopupTimerInMilSec);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res) => {
        if (res !== null && (!this.isSubscribedEmail || !this.isSubscribedPhone)) {
          this.eweModal.open();
        }
      });
  }

  submitEwe(event: Event) {
    event.preventDefault();
    this.eweModal.dismiss();
    combineLatest([
      this.userManagementFacade.getUserId(),
      this.customerServiceFacade.getCustomer(),
      this.userManagementFacade.getUsername(),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(([userId, customer, username]) => {
          return this.consentService.createCustomerConsent(
            {
              firstName: customer.firstname,
              lastName: customer.lastname,
              salutation: customer.salutation as Salutation,
              email: this.formGroup.get('subscribeEmail').value === true ? username : null,
              phoneNumber: this.formGroup.get('subscribePhone').value === true ? customer.phone : null,
            },
            userId
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
