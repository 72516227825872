import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerServiceStore, Message, MessageService } from '@nbg-digital/customer-service/domain';
import { Observable } from 'rxjs';

@Component({
  selector: 'nv-customer-postbox',
  styleUrls: ['postbox-details.component.scss'],
  templateUrl: 'postbox-details.component.html',
})
export class PostboxDetailsComponent implements OnInit {
  messageId: string = null;
  messages$: Observable<Message[]>;

  constructor(
    private route: ActivatedRoute,
    private store: CustomerServiceStore,
    private messageService: MessageService
  ) {
    this.messageId = this.route.snapshot.paramMap.get('messageId');
  }

  ngOnInit() {
    this.messages$ = this.store.selectMessages();
    if (this.messageId) {
      this.markMessageAsRead(this.messageId);
    }
  }

  markMessageAsRead(id: string) {
    this.messageService.setReadMessage(id).subscribe(() => this.store.setMessageRead(id));
  }
}
