import { Customer, CustomerServiceStore, ProfileService } from '@nbg-digital/customer-service/domain';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap, first } from 'rxjs/operators';

@Injectable()
export class ContractOverviewCustomerResolver implements Resolve<Customer> {
  constructor(private profileService: ProfileService, private customerServiceStore: CustomerServiceStore) {}

  resolve(): Observable<Customer> {
    return this.customerServiceStore.selectCustomer().pipe(
      first(),
      switchMap((res) => {
        if (res === null) {
          return this.profileService.getPersonalData().pipe(
            tap((customer) => {
              this.customerServiceStore.update({ customer });
            })
          );
        }
        return of(res);
      })
    );
  }
}
