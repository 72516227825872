import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment, Message, MessageService, MessageType } from '@nbg-digital/customer-service/domain';

@Component({
  selector: 'nv-customer-message',
  styleUrls: ['message.component.scss'],
  templateUrl: 'message.component.html',
})
export class MessageComponent {
  @Input() isCollapsed = true;
  @Input() message: Message;
  @Output() messageRead: EventEmitter<string> = new EventEmitter();

  MessageType = MessageType;

  constructor(private messageService: MessageService) {}

  readMessage(id: string) {
    if (!this.message.read) {
      this.messageRead.emit(id);
    }
  }

  getAttachment($event: MouseEvent, attachment: Attachment) {
    $event.preventDefault();
    this.messageService.getAttachment(attachment).subscribe();
  }
}
