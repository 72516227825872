import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Country, CountryService } from '@nbg-digital/shared/util';
import { Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class CountryResolver implements Resolve<any> {
  constructor(private countryService: CountryService) {}

  resolve(): Observable<Country[]> {
    return this.countryService.getCountryList();
  }
}
