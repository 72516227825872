import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApplicationComponent } from './pages/application/application.component';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricingChangeGuard implements CanDeactivate<ApplicationComponent> {
  constructor(@Inject('pricingPath') private pricingPath: string, private riskAssessmentFacade: RiskAssessmentFacade) {}

  canDeactivate(
    component: ApplicationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.riskAssessmentFacade.isRiskAssessmentComplete$.pipe(
      first(),
      switchMap((value) => {
        if (value && nextState.url.includes(`/${this.pricingPath}/`)) {
          return component.openModal();
        }
        return of(true);
      })
    );
  }
}
