import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChangeCustomerDetailsComponent } from './change-customer-details/change-customer-details.component';
import {
  NvAutoCompleteModule,
  NvButtonModule,
  NvCardModule,
  NvDatePickerModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { CustomerDataComponent } from './customer-data.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/customer-details/', '.json');
}

@NgModule({
  declarations: [CustomerDataComponent, CustomerDetailsComponent, ChangeCustomerDetailsComponent],
  imports: [
    CommonModule,
    NvCardModule,
    NvButtonModule,
    NvDatePickerModule,
    ReactiveFormsModule,
    NvFormModule,
    NvModalModule,
    NvErrorModule,
    NvInputModule,
    NvLinkModule,
    NvAutoCompleteModule,
    RouterModule.forChild([
      {
        path: '',
        component: CustomerDataComponent,
        children: [
          {
            path: '',
            component: CustomerDetailsComponent,
          },
          {
            path: 'persoenliche-daten-aendern',
            component: ChangeCustomerDetailsComponent,
          },
        ],
      },
    ]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
  ],
  exports: [RouterModule],
})
export class CustomerDataModule extends ModuleWithTranslations {
  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
