import { Component, ViewChild } from '@angular/core';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';

@Component({
  selector: 'nv-kupo-new-features-modal',
  templateUrl: './new-features-modal.component.html',
  styleUrls: ['./new-features-modal.component.scss'],
})
export class NewFeaturesModalComponent {
  public isLastSlide$: Observable<boolean>;

  @ViewChild('newFeaturesModal') newFeaturesModal: NvModalComponent;

  @ViewChild('carousel') set carousel(value: NgbCarousel) {
    this.isLastSlide$ = value?.slide.pipe(
      map((event: NgbSlideEvent) => event.current === this.newFeatures[this.newFeatures.length - 1])
    );
  }

  public newFeatures = ['message_modal', 'send_message'];
  public isLoggedIn$ = this.userManagementFacade.isAuthenticated$;

  constructor(public userManagementFacade: UserManagementFacade) {}
}
