import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { CustomerServiceFacade } from '@nbg-digital/customer-service/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-kupo-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  @HostBinding('class.nv-evox-root') rootClass = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private userManagementFacade: UserManagementFacade,
    private customerServiceFacade: CustomerServiceFacade,
    public loaderService: LoaderService,
    private router: Router,
    @Inject('landingPageUrl') public landingPageUrl: string,
    @Inject('enableConstructionPage') public enableConstructionPage: boolean
  ) {
    this.translate.setDefaultLang('de');

    this.userManagementFacade.logOut$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.customerServiceFacade.resetState();
    });

    this.userManagementFacade.automaticLogout$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.router.navigate(['/login'], { queryParams: { automaticLogout: true } }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
