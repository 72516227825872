import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthIllegalGuard } from './auth/auth.guard';
import { ErrorComponent } from './error/error.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProtectedAreaComponent } from './protected-area/protected-area.component';
import {
  ContractOverviewContractsResolver,
  ContractOverviewCustomerResolver,
} from '@nbg-digital/customer-service/feature';
import { OverviewComponent } from './pages/overview/overview.component';
import { CountryResolver } from '@nbg-digital/application/feature';

export const LOGIN_PATH = 'login';
export const PASSWORD_FORGOT_PATH = 'passwort-vergessen';
export const PASSWORD_RECOVERY_PATH = 'password-reset/:uuid'; // TODO: change link to password-zuruecksetzen after we shut down old kupo due to german conventions
export const ERROR_PATH = 'error';
export const OVERVIEW_PATH = 'uebersicht';
export const PROFILE_PATH = 'profil';
export const CONTRACT_DETAIL = 'vertrag/:product/:contractId';
export const POSTBOX = 'postfach';

const routes: Routes = [
  {
    path: '',
    component: ProtectedAreaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: OVERVIEW_PATH,
        pathMatch: 'full',
      },
      {
        path: OVERVIEW_PATH,
        resolve: {
          contracts: ContractOverviewContractsResolver,
          customer: ContractOverviewCustomerResolver,
          countries: CountryResolver,
        },
        component: OverviewComponent,
      },
      {
        path: POSTBOX,
        loadChildren: () => import('@nbg-digital/customer-service/feature').then((m) => m.PostboxDetailsModule),
      },
      {
        path: PROFILE_PATH,
        component: ProfileComponent,
        children: [
          {
            path: 'persoenliche-daten',
            loadChildren: () => import('@nbg-digital/customer-service/feature').then((m) => m.CustomerDataModule),
          },
          {
            path: 'zugangsdaten',
            loadChildren: () => import('@nbg-digital/user-management/feature').then((m) => m.LoginDataModule),
          },
        ],
      },
      {
        path: CONTRACT_DETAIL,
        loadChildren: () => import('@nbg-digital/customer-service/feature').then((m) => m.ContractDetailsModule),
      },
    ],
  },
  {
    path: LOGIN_PATH,
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthIllegalGuard],
  },
  {
    path: PASSWORD_FORGOT_PATH,
    loadChildren: () => import('@nbg-digital/user-management/feature').then((e) => e.PasswordForgotModule),
    canActivate: [AuthIllegalGuard],
  },
  {
    path: PASSWORD_RECOVERY_PATH,
    loadChildren: () => import('@nbg-digital/user-management/feature').then((e) => e.PasswordRecoveryModule),
    canActivate: [AuthIllegalGuard],
  },
  {
    path: ERROR_PATH,
    component: ErrorComponent,
  },
  {
    path: 'ewe',
    loadChildren: () => import('@nbg-digital/consent-management/feature').then((m) => m.ConsentManagementFeatureModule),
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [ContractOverviewContractsResolver, ContractOverviewCustomerResolver, CountryResolver],
})
export class AppRoutingModule {}
