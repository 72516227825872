import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractDetailsComponent } from './contract-details.component';
import { RouterModule } from '@angular/router';
import {
  NvButtonModule,
  NvCardModule,
  NvCheckboxModule,
  NvDatePickerModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ContractDetailsResolver } from './contract-details.resolver';
import { PolicySpecificsRlvComponent } from './policy-specifics-rlv/policy-specifics-rlv.component';
import { PolicySpecificsDkrComponent } from './policy-specifics-dkr/policy-specifics-dkr.component';
import { PolicySpecificsEksComponent } from './policy-specifics-eks/policy-specifics-eks.component';
import { PaymentChangeComponent } from './payment-change/payment-change.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentDataModule } from '../components/payment-data/payment-data.module';

export function createContractDetailTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/contract-details/', '.json');
}

@NgModule({
  declarations: [
    ContractDetailsComponent,
    PolicySpecificsRlvComponent,
    PolicySpecificsDkrComponent,
    PolicySpecificsEksComponent,
    PaymentChangeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NvButtonModule,
    NvModalModule,
    NvCardModule,
    NvCheckboxModule,
    NvDatePickerModule,
    NvFormModule,
    NvErrorModule,
    NvInputModule,
    NvLinkModule,
    RouterModule.forChild([
      {
        path: '',
        component: ContractDetailsComponent,
        resolve: { contractDetails: ContractDetailsResolver },
      },
      {
        path: 'bankdaten',
        component: PaymentChangeComponent,
      },
    ]),
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createContractDetailTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
    PaymentDataModule,
  ],
  providers: [ContractDetailsResolver, { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['de'] } }],
})
export class ContractDetailsModule extends ModuleWithTranslations {
  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
