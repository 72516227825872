import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OfferExpiredComponent } from './offer-expired.component';
import { TranslateModule } from '@ngx-translate/core';
import { NvButtonModule } from '@nbg-digital/ui-dpl-components';

const routes: Routes = [
  {
    path: '',
    component: OfferExpiredComponent,
  },
];

@NgModule({
  imports: [NvButtonModule, CommonModule, TranslateModule, RouterModule.forChild(routes)],
  declarations: [OfferExpiredComponent],
})
export class OfferExpiredModule {}
